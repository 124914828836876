/* eslint-disable no-return-assign */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import useVerticalIndex from '@/src/hooks/useVerticalIndex';
import AnchorLink from '@/src/components/AnchorLink';
import ButtonNew from '@/src/components/ButtonNew';
import { trackClickEvent } from '@utils/analytics';
import { SORTING_HAT } from '@/src/constants/routes';
import { ReactComponent as Banner } from '../img/banner.svg';
import { ReactComponent as GryffindorCrest } from '../img/gryffindor-crest.svg';
import { ReactComponent as RavenclawCrest } from '../img/ravenclaw-crest.svg';
import { ReactComponent as HufflepuffCrest } from '../img/hufflepuff-crest.svg';
import { ReactComponent as SlytherinCrest } from '../img/slytherin-crest.svg';
import s from './LoggedOut.module.scss';

const AnimatedNumbers = dynamic(() => import('react-animated-numbers'), {
  ssr: false,
});

const houses = ['Gryffindor', 'Ravenclaw', 'Hufflepuff', 'Slytherin'];

const BANNER_STARTING_POSITION = -70;

const propTypes = {
  isInView: PropTypes.bool,
  analyticsParams: PropTypes.shape({
    verticalIndex: PropTypes.number.isRequired,
    rootContentfulId: PropTypes.string.isRequired,
    rootContentTypeId: PropTypes.string.isRequired,
  }),
  housePercentages: PropTypes.shape({
    gryffindor: PropTypes.number.isRequired,
    ravenclaw: PropTypes.number.isRequired,
    hufflepuff: PropTypes.number.isRequired,
    slytherin: PropTypes.number.isRequired,
  }).isRequired,
};

const LoggedOut = ({ isInView = false, analyticsParams = {}, housePercentages }) => {
  const [sortingDataHold, setSortingDataHold] = useState(null);
  const [houseSortingData, setHouseSortingData] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const bannerRefs = useRef([]);
  const { verticalIndex, componentRef } = useVerticalIndex();
  const { rootContentTypeId: contentTypeId, rootContentfulId: contentfulId } = analyticsParams;

  useEffect(() => {
    setSortingDataHold(housePercentages);
  }, [housePercentages]);

  useEffect(() => {
    let sortingTimeout;
    if (isInView && sortingDataHold) {
      sortingTimeout = setTimeout(() => {
        setHouseSortingData(sortingDataHold);
      }, 500);
    }
    return () => {
      clearTimeout(sortingTimeout);
    };
  }, [isInView, sortingDataHold]);

  const calculatePosition = (lowercaseHouse) => {
    return BANNER_STARTING_POSITION + houseSortingData[lowercaseHouse];
  };

  const renderHousResultByHouse = (upperCaseHouse, i) => {
    let newPercentageNumber;

    const lowercaseHouse = upperCaseHouse.toLowerCase();
    if (houseSortingData?.[lowercaseHouse]) {
      newPercentageNumber = calculatePosition(lowercaseHouse);
    }

    const renderHouseCrest = (house) => {
      switch (house) {
        case 'Gryffindor':
          return <GryffindorCrest className={[s.crest, s.gryffindor].join(' ')} />;
        case 'Ravenclaw':
          return <RavenclawCrest className={[s.crest, s.ravenclaw].join(' ')} />;
        case 'Hufflepuff':
          return <HufflepuffCrest className={[s.crest, s.hufflepuff].join(' ')} />;
        case 'Slytherin':
          return <SlytherinCrest className={[s.crest, s.slytherin].join(' ')} />;
        /* istanbul ignore next */
        default:
          return null;
      }
    };

    return (
      <div className={s.houseResult} key={lowercaseHouse} ref={componentRef}>
        <div
          className={[
            s.bannerContainer,
            /* istanbul ignore next */
            hoverIndex === i ? s.isHovered : '',
            /* istanbul ignore next */
            hoverIndex !== null && hoverIndex !== i ? s.hide : '',
          ].join(' ')}
          style={{
            top: houseSortingData ? `${newPercentageNumber}px` : `${BANNER_STARTING_POSITION}px`,
          }}
          data-testid={`${lowercaseHouse}-${houseSortingData?.[lowercaseHouse]}`}
          ref={(element) => (bannerRefs.current[i] = element)}
        >
          <Banner className={[s.banner, s[lowercaseHouse]].join(' ')} />
          {renderHouseCrest(upperCaseHouse)}
          <div className={s.detailContainer}>
            <div className={s.house}>{upperCaseHouse}</div>
            <div className={[s.percentage, houseSortingData ? '' : s.hide].join(' ')}>
              {houseSortingData?.[lowercaseHouse] && (
                <>
                  <AnimatedNumbers animateToNumber={houseSortingData[lowercaseHouse]}>
                    0
                  </AnimatedNumbers>
                  %
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={s.container}>
      <div className={s.leftColumn}>
        <h2 className={[s.goldHeading, s.mobileOnly].join(' ')}>
          What&apos;s Your
          <br />
          Hogwarts House?
        </h2>
        <div className={s.innerContainer}>
          <div className={s.headingOuterContainer}>
            <div className={s.headingContainer} />
          </div>

          <div className={s.shadowOverlay}>
            <div className={s.hoverBanners}>
              {houses.map((house, index) => {
                return (
                  <div
                    className={s.hoverBanner}
                    onMouseEnter={
                      /* istanbul ignore next */ () => {
                        if (!houseSortingData) return;
                        setHoverIndex(index);
                      }
                    }
                    onMouseLeave={
                      /* istanbul ignore next */ () => {
                        if (!houseSortingData) return;
                        setHoverIndex(null);
                      }
                    }
                    key={house}
                  />
                );
              })}
            </div>
          </div>
          <div className={s.outerOuterContainer}>
            <div className={s.houseResultsOuterContainer}>
              <div className={s.houseResultsContainer}>
                {houses.map((house, index) => {
                  return renderHousResultByHouse(house, index);
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.rightColumn}>
        <h2 className={s.goldHeading}>
          What&apos;s Your
          <br />
          Hogwarts House?
        </h2>
        <AnchorLink
          href={SORTING_HAT}
          onClick={() => {
            trackClickEvent(
              {
                destination_url: SORTING_HAT,
                label: 'GET SORTED NOW',
                location: 'User Identity Checklist',
                vertical_index: verticalIndex,
                horizontal_index: 0,
                content_name: "What's Your Hogwarts House?",
              },
              { contentfulId, contentTypeId },
            );
          }}
        >
          <ButtonNew
            theme="modern"
            level="primary"
            color="purple"
            label="Get Sorted Now"
            isGradient
          />
        </AnchorLink>
      </div>
    </div>
  );
};

LoggedOut.propTypes = propTypes;
export default LoggedOut;
